import { useEffect, useState } from "react";
import { showAsteriskIfEmpty } from '../../utils/helpers/asterisk';

interface SponsorDetailsProps {
  sponsorAsicId: string;
  setSponsorAsicId: (newSponsorAsicId: string) => void;
  sponsorAsicExpiryMonth: string;
  setSponsorAsicExpiryMonth: (newSponsorAsicExpiryMonth: string) => void;
  sponsorAsicExpiryYear: string;
  setSponsorAsicExpiryYear: (newSponsorAsicExpiryYear: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}

export const SponsorDetailsContinued = (props: SponsorDetailsProps) => {
  const [sponsorAsicExpired, setSponsorAsicExpired] = useState<boolean>(false);

  useEffect(() => {
    props.setInvalidForm(
        !props.sponsorAsicId ||
        !props.sponsorAsicExpiryMonth ||
        (!props.sponsorAsicExpiryYear)
    );

    const today = new Date();
    const expiryDate = new Date(
      parseInt(props.sponsorAsicExpiryYear),
      parseInt(props.sponsorAsicExpiryMonth) - 1,
      1
    );
    if (expiryDate < today) {
      setSponsorAsicExpired(true);
      props.setInvalidForm(true);
    } else {
      setSponsorAsicExpired(false);
    }
  }, [

    props.sponsorAsicId,
    props.sponsorAsicExpiryMonth,
    props.sponsorAsicExpiryYear,
  ]);

  return (
    <div className="mt-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label htmlFor="sponsor-asic-id" className="block text-xs font-medium text-gray-900">
              Sponsor ASIC ID{showAsteriskIfEmpty(props.sponsorAsicId)}
            </label>
            <input
              id="sponsor-asic-id"
              name="sponsor-name"
              type="text"
              autoComplete="new-sponsor-asic-id"
              required
              value={props.sponsorAsicId}
              onChange={(e) => props.setSponsorAsicId(e.target.value.toUpperCase())}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label htmlFor="sponsor-asic-expiry-month" className="block text-xs font-medium text-gray-900">
              Sponsor ASIC Expiry Month{showAsteriskIfEmpty(props.sponsorAsicExpiryMonth)}
            </label>
            <select
              id="sponsor-asic-expiry-month"
              name="sponsor-asic-expiry-month"
              autoComplete="new-sponsor-asic-expiry-month"
              value={props.sponsorAsicExpiryMonth || ""}
              onChange={(e) => props.setSponsorAsicExpiryMonth(e.target.value)}
              required
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label htmlFor="sponsor-asic-expiry-year" className="block text-xs font-medium text-gray-900">
              Sponsor ASIC Expiry Year{showAsteriskIfEmpty(props.sponsorAsicExpiryYear)}
            </label>
            <select
              id="sponsor-asic-expiry-year"
              name="sponsor-asic-expiry-year"
              autoComplete="new-sponsor-asic-expiry-year"
              value={props.sponsorAsicExpiryYear}
              onChange={(e) => props.setSponsorAsicExpiryYear(e.target.value)}
              required
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            >
              <option value="">Select Year</option>
              <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
              <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
              <option value={new Date().getFullYear() + 2}>{new Date().getFullYear() + 2}</option>
            </select>
          </div>
        </div>

        <div className="col-span-6">
          {sponsorAsicExpired && (
            <div className="text-xs font-medium text-red-500">
              Please choose an ASIC expiry month and year that is not currently expired.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};