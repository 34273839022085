import { Transition } from "@headlessui/react";
import {
  CameraIcon,
  CheckCircleIcon,
  CheckIcon,
  ClipboardListIcon,
  DotsCircleHorizontalIcon,
  IdentificationIcon,
  PlusIcon,
  TicketIcon,
  UserCircleIcon,
  UsersIcon,
  XIcon,
  ArrowRightIcon,
  ArrowLeftIcon
} from "@heroicons/react/outline";

import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar/NavBar";
import api from "../../utils/helpers/api";
import { FacePhoto } from "./FacePhoto";
import { IDDetails } from "./IDDetails";
import { IDPhoto } from "./IDPhoto";
import { PassDetails } from "./PassDetails";
import { PersonalDetails } from "./PersonalDetails";
import { SponsorDetails } from "./SponsorDetails";
import { SponsorDetailsContinued } from "./SponsorDetailsContinued";
import { TermsAndConditions } from "./TermsAndConditions";
import formatISO from "date-fns/formatISO";
import { format } from "path";
import CancelModal from "../../components/CancelModal/CancelModal";
import CancelIcon from '@mui/icons-material/Cancel';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const CreateVisitorPass = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [invalidForm, setInvalidForm] = useState<boolean>(true);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [idPhotoFront, setIDPhotoFront] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [operationalNeed, setOperationalNeed] = useState<string>("");
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] = useState<string>(
    (new Date().getMonth() + 1).toString()
  );
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    (new Date().getFullYear() + 1).toString()
  );
  const [sponsorCompany, setSponsorCompany] = useState<string>("");
  const [sponsorEmail, setSponsorEmail] = useState<string>("");
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("");
  const [idType, setIDType] = useState<string>("Driver's License");
  const [idNumber, setIDNumber] = useState<string>("");
  const [airportId, setAirportId] = useState<string>("");
  const [rangeType, setRangeType] = useState<string>("24 Hours");
  const [startTime, setStartTime] = useState<string>(
    formatISO(new Date(), { representation: "complete" }).slice(0, -9)
  );
  const [endTime, setEndTime] = useState<string>(
    formatISO(addDays(new Date(), 1), { representation: "complete" }).slice(
      0,
      -9
    )
  );
  
  const handleSetCurrentStep = (newStep: number) => {
    setCurrentStep(newStep);
  };
  
  
  const areAllStepsValid = () => {

    const requiredFieldsFilled = firstName !== "" &&
      lastName !== "" &&
      company !== "" &&
      facePhoto !== "" &&
      idPhotoFront !== "" &&
      idNumber !== "" &&
      sponsorName !== "" &&
      sponsorAsicId !== "" &&
      sponsorAsicExpiryMonth !== "" &&
      sponsorAsicExpiryYear !== "" &&
      sponsorCompany !== "" &&
      operationalNeed !== "" &&
      airportId !== "";

    const sponsorContactInfoProvided = sponsorEmail !== "" || sponsorMobileNumber !== "";

    return requiredFieldsFilled && sponsorContactInfoProvided;
  };


  const [checkTerms, setCheckTerms] = useState<boolean>(false);
  const [checkDampPolicy, setCheckDampPolicy] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);

  useEffect(() => {
    setInvalidForm(!checkTerms || !checkDampPolicy);
  }, [checkTerms, checkDampPolicy]);
  

  const getStepStatus = (stepId: number) => {
    return currentStep === stepId
      ? "current"
      : currentStep > stepId
      ? "complete"
      : "upcoming";
  };

  const steps = [
    {
      id: 1,
      name: "Personal Details",
      href: "#personal-details",
      status: getStepStatus(1),
      icon: <UserCircleIcon className="h-6 w-auto" />,
      component: (
        <PersonalDetails
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          company={company}
          setCompany={setCompany}
          email={email}
          setEmail={setEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 2,
      name: "Sponsor Details",
      href: "#sponsor-details",
      status: getStepStatus(2),
      icon: <UsersIcon className="h-6 w-auto" />,
      component: (
        <SponsorDetails
          sponsorName={sponsorName}
          setSponsorName={setSponsorName}
          sponsorAsicId={sponsorAsicId}
          setSponsorAsicId={setSponsorAsicId}
          sponsorAsicExpiryMonth={sponsorAsicExpiryMonth}
          setSponsorAsicExpiryMonth={setSponsorAsicExpiryMonth}
          sponsorAsicExpiryYear={sponsorAsicExpiryYear}
          setSponsorAsicExpiryYear={setSponsorAsicExpiryYear}
          sponsorCompany={sponsorCompany}
          setSponsorCompany={setSponsorCompany}
          sponsorEmail={sponsorEmail}
          setSponsorEmail={setSponsorEmail}
          sponsorMobileNumber={sponsorMobileNumber}
          setSponsorMobileNumber={setSponsorMobileNumber}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 3,
      name: "Sponsor Details",
      href: "#sponsor-details-continued",
      status: getStepStatus(3),
      icon: <UsersIcon className="h-6 w-auto" />,
      component: (
        <SponsorDetailsContinued
         
          sponsorAsicId={sponsorAsicId}
          setSponsorAsicId={setSponsorAsicId}
          sponsorAsicExpiryMonth={sponsorAsicExpiryMonth}
          setSponsorAsicExpiryMonth={setSponsorAsicExpiryMonth}
          sponsorAsicExpiryYear={sponsorAsicExpiryYear}
          setSponsorAsicExpiryYear={setSponsorAsicExpiryYear}
      
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 4,
      name: "ID Photo",
      href: "#id-photo",
      status: getStepStatus(4),
      icon: <IdentificationIcon className="h-6 w-auto" />,
      component: (
        <IDPhoto
          idPhotoFront={idPhotoFront}
          setIdPhotoFront={setIDPhotoFront}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 5,
      name: "Face Photo",
      href: "#face-photo",
      status: getStepStatus(5),
      icon: <CameraIcon className="h-6 w-auto" />,
      component: (
        <FacePhoto
          facePhoto={facePhoto}
          setFacePhoto={setFacePhoto}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 6,
      name: "ID Details",
      description: "Provide your identification details",
      href: "#id-details",
      status: getStepStatus(6),
      icon: <CheckCircleIcon className="h-6 w-auto" />,
      component: (
        <IDDetails
          idType={idType}
          idNumber={idNumber}
          setIDNumber={setIDNumber}
          setIDType={setIDType}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 7,
      name: "Pass Details",
      href: "#pass-details",
      status: getStepStatus(7),
      icon: <TicketIcon className="h-6 w-auto" />,
      component: (
        <PassDetails
          operationalNeed={operationalNeed}
          setOperationalNeed={setOperationalNeed}
          airportId={airportId}
          setAirportId={setAirportId}
          rangeType={rangeType}
          setRangeType={setRangeType}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 8,
      name: "Terms and Conditions",
      href: "#terms-and-conditions",
      status: getStepStatus(8),
      icon: <ClipboardListIcon className="h-6 w-auto" />,
      component: (
        <TermsAndConditions
          checkTerms={checkTerms}
          setCheckTerms={setCheckTerms}
          checkDampPolicy={checkDampPolicy}
          setCheckDampPolicy={setCheckDampPolicy}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
  ];

  useEffect(() => {
    const startTimeDate = new Date(startTime);
    if (rangeType === "24 Hours") {
      setEndTime(
        formatISO(addDays(startTimeDate, 1), {
          representation: "complete",
        }).slice(0, -9)
      );
    } else if (rangeType === "1 Week") {
      setEndTime(
        formatISO(addDays(startTimeDate, 7), {
          representation: "complete",
        }).slice(0, -9)
      );
    } else if (rangeType === "1 Month") {
      setEndTime(
        formatISO(addMonths(startTimeDate, 1), {
          representation: "complete",
        }).slice(0, -9)
      );
    }
  }, [startTime, rangeType]);

  const createVisitorPass = async () => {
    setLoading(true);
    try {
      await api.createVisitorPass({
        firstName,
        lastName,
        email,
        mobileNumber,
        company,
        facePhoto,
        idPhotoFront,
        idType,
        idNumber,
        sponsorAsicId,
        sponsorName,
        sponsorAsicExpiryMonth,
        sponsorAsicExpiryYear,
        sponsorCompany,
        sponsorEmail,
        sponsorMobileNumber,
        operationalNeed,
        rangeType,
        startTime,
        endTime,
        airportId,
      });
      setSuccess(true);
      setTimeout(() => navigate("/dashboard"), 3000);
    } catch (error) {
      console.error("Error while creating visitor pass - ", error);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="min-h-full">
        <NavBar />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-1 sm:py-3">
          <h1 className="hidden text-2xl font-semibold md:flex flex-row items-center pt-2">
            <PlusIcon className="h-7 w-auto mr-1.5" />
            Request Visitor Pass
          </h1>
        </div>
        
        <div className="py-2 md:py-4">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-0 sm:py-3 lg:grid lg:grid-cols-12 lg:gap-4">
            <main className="lg:col-span-12 xl:col-span-10">
            <div className="flex items-center justify-center w-full">
          </div>
          <div className="lg:border-b lg:border-t lg:border-gray-200 mb-8 relative">
            <nav className="flex flex-col items-center justify-center text-center" aria-label="Progress">
            <p className="text-lg font-semibold mb-2">
              <span className="ml-3.5">
                {steps[currentStep - 1].name} (Step {currentStep}/{steps.length})
              </span>
            </p>
            <ol role="list" className="flex items-center space-x-5">
              {steps.map((step) => (
                <li key={step.name}>
                  <a
                    href={step.href}
                    onClick={(e) => {
                      e.preventDefault(); 
                      setCurrentStep(step.id); 
                    }}
                    className={classNames(
                      step.status === 'complete' ? 'bg-amber-500 hover:bg-amber-600' : '',
                      step.status === 'current' ? 'bg-amber-200' : '',
                      (step.status !== 'complete' && step.status !== 'current') ? 'bg-gray-200 hover:bg-gray-400' : '',
                      typeof step.status === 'boolean' && step.status ? 'bg-amber-500 hover:bg-amber-600' : '', 
                      'block h-2.5 w-2.5 rounded-full'
                    )}
                  >
                    <span className="sr-only">{step.name}</span>
                  </a>
                </li>
              ))}
            </ol>
            </nav>
            <button
              type="submit"
              onClick={() => setOpenCancelModal(true)}
              className="text-sm sm:text-base font-medium text-white px-3 py-1 rounded-md cancel-button ml-auto mr-30 absolute top-1/2 transform -translate-y-1/2 right-0"
            >
              <CancelIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </button>
          </div>

            {steps[currentStep - 1].component ?? ""}
            <div className="flex flex-row items-center justify-between mt-4 w-full">
              {currentStep > 1 && (
                <div className="w-1/3 sm:w-1/4 ml-2 sm:ml-4">
                  <button
                    type="submit"
                    disabled={success || loading}
                    onClick={() =>
                      currentStep === 1
                        ? navigate("/dashboard")
                        : setCurrentStep(currentStep - 1)
                    }
                    className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:opacity-50"
                  >
                    <ArrowLeftIcon className="mr-2 -ml-1 h-5 w-5" aria-hidden="true" />
                    Previous
                  </button>
                </div>
              )}
              <CancelModal
                open={openCancelModal}
                setOpen={setOpenCancelModal}
                title={"Cancel Visitor Pass Request"}
                description={
                  "Are you sure you want to cancel this visitor pass request? You will not be able to recover your progress if you cancel."
                }
                action={() => {
                  setOpenCancelModal(false);
                  navigate("/dashboard");
                }}
              />
              <div className="w-1/3 sm:w-1/4 ml-2 sm:ml-4">
              <button
                type="submit"
                onClick={() => {
                  if (currentStep === steps.length && areAllStepsValid() && checkTerms && checkDampPolicy) {
                    createVisitorPass();
                  } else {
                    handleSetCurrentStep(currentStep + 1);
                  }
                }}
                disabled={loading || success || (currentStep === steps.length && (!areAllStepsValid() || !checkTerms || !checkDampPolicy))}
                className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
              >
                Next
                <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
              </button>
             </div>
            </div>
          </main>
            {invalidForm && (
              <div className="col-span-12 flex flex-row text-sm font-medium text-red-500 mt-2">
                <div className="my-1">
                  Please complete all required information (*) to continue
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={loading}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <DotsCircleHorizontalIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Submitting...
                      </p>
                      <p className="mt-1 text-sm text-gray-500">Please wait</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={success}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Successfully requested a visitor pass
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please wait for it to be approved
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setSuccess(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={error}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <XIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        An error occurred
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please try again or contact support
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </>
  );
};