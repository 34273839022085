import { useEffect, useState } from "react";
import { showAsteriskIfEmpty } from '../../utils/helpers/asterisk';

interface SponsorDetailsProps {
  sponsorName: string;
  setSponsorName: (newSponsorName: string) => void;
  sponsorAsicId: string;
  setSponsorAsicId: (newSponsorAsicId: string) => void;
  sponsorAsicExpiryMonth: string;
  setSponsorAsicExpiryMonth: (newSponsorAsicExpiryMonth: string) => void;
  sponsorAsicExpiryYear: string;
  setSponsorAsicExpiryYear: (newSponsorAsicExpiryYear: string) => void;
  sponsorCompany: string;
  setSponsorCompany: (newSponsorCompany: string) => void;
  sponsorEmail: string;
  setSponsorEmail: (newSponsorEmail: string) => void;
  sponsorMobileNumber: string;
  setSponsorMobileNumber: (newSponsorMobileNumber: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}

export const SponsorDetails = (props: SponsorDetailsProps) => {
  const [sponsorAsicExpired, setSponsorAsicExpired] = useState<boolean>(false);

  useEffect(() => {
    props.setInvalidForm(
      !props.sponsorName ||
      !props.sponsorCompany ||
      (!props.sponsorEmail && !props.sponsorMobileNumber)
    );
  }, [props.sponsorName, props.sponsorCompany, props.sponsorEmail, props.sponsorMobileNumber]);

  return (
    <div className="mt-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="sponsor-name" className="block text-xs font-medium text-gray-900">
              Sponsor Name{showAsteriskIfEmpty(props.sponsorName)}
            </label>
            <input
              id="sponsor-name"
              name="sponsor-name"
              type="text"
              autoComplete="new-sponsor-name"
              required
              value={props.sponsorName}
              onChange={(e) => props.setSponsorName(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="sponsor-company" className="block text-xs font-medium text-gray-900">
              Sponsor Company{showAsteriskIfEmpty(props.sponsorCompany)}
            </label>
            <input
              id="sponsor-company"
              name="sponsor-company"
              type="text"
              autoComplete="new-sponsor-company"
              required
              value={props.sponsorCompany}
              onChange={(e) => props.setSponsorCompany(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"    
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="sponsor-email" className="block text-xs font-medium text-gray-900">
              Sponsor Email
              {showAsteriskIfEmpty(
                props.sponsorMobileNumber || props.sponsorEmail ? "non-empty" : ""
              )}
            </label>
            <input
              id="sponsor-email"
              name="sponsor-email"
              type="text"
              autoComplete="new-sponsor-email"
              required
              value={props.sponsorEmail}
              onChange={(e) => props.setSponsorEmail(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"   
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="sponsor-mobile" className="block text-xs font-medium text-gray-900">
              Sponsor Mobile Number
              {showAsteriskIfEmpty(
                props.sponsorMobileNumber || props.sponsorEmail ? "non-empty" : ""
              )}
            </label>
            <input
              id="sponsor-mobile"
              name="sponsor-mobile"
              type="text"
              autoComplete="new-sponsor-mobile"
              required
              value={props.sponsorMobileNumber}
              onChange={(e) => props.setSponsorMobileNumber(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"   
            />
          </div>
        </div>

        {sponsorAsicExpired && (
          <div className="col-span-6 flex flex-row text-sm font-medium text-red-500 mt-3">
            <div className="my-1">
              Please choose an ASIC expiry month and year that is not currently expired.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};